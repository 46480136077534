<template>
  <div class="condition-chip pa-2" :style="{ borderRadius }">
    <Typography :size="size"><slot /></Typography>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';
export default defineComponent({
  name: 'ConditionChip',
  components: { Typography },
  props: {
    size: {
      String,
      default: 'xs'
    },
    borderRadius: {
      String
    }
  }
});
</script>
<style lang="scss" scoped>
.condition-chip {
  display: inline-block;
  background-color: var(--primary-light-color);
}
</style>
