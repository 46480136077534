
import { useRoute, useRouter } from 'vue-router';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';
import Flex from '@/components/layout/Flex.vue';
import Box from '@/components/layout/Box.vue';
import Card from '@/components/ui/Card.vue';
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';
import Typography from '@/components/ui/Typography.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import SelectArea from '@/components/ui/SelectArea.vue';
import SelectTarget from '@/components/ui/SelectTarget.vue';
import RadioButtonGroup from '@/components/ui/RadioButtonGroup.vue';
import LoadingMessage from '@/components/ui/LoadingMessage.vue';
import TimeoutScreen from '@/components/ui/TimeoutScreen.vue';
import {
  useHeatMapData,
  useHeatMap
} from '@/composables/datasearch/timeslot/heatmap';
import Heatmap from '@/pages/datasearch/timeslot/Heatmap.vue';
import { computed, defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { COMPANY_ROUTES, CUSTOM_ROUTES, DATASEARCH_ROUTES } from '@/router';
import { AGGREGATION_UNIT, TARGET_NAME } from '@/common/constant';
import { TimeslotSearchConditionDataDivisionEnum } from '@/api/openapi';
import { scrollTo } from 'vue-scrollto';
import { toast } from '@/components/ui/Toast';
import FormCol from '@/components/ui/FormCol.vue';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';

export default defineComponent({
  components: {
    FormCol,
    Box,
    Button,
    Card,
    Flex,
    Modal,
    SelectTarget,
    Typography,
    LayoutWithSidebar,
    DatePicker,
    RadioButtonGroup,
    LoadingMessage,
    TimeoutScreen,
    Heatmap,
    SelectArea,
    ErrorMessage
  },
  setup() {
    const breadcrumbs = ref({
      parents: [
        { name: COMPANY_ROUTES.top, label: 'ホーム' },
        { name: DATASEARCH_ROUTES.timeslot, label: 'TVデータサーチ' }
      ],
      current: { label: 'ターゲットヒートマップ' }
    });
    const captions = reactive([
      { label: '単位', text: '%' },
      { label: '固定値', text: '7段階の固定値の範囲で色分け' },
      {
        label: '変動値',
        text:
          '検索結果の最大値と最小値から7段階に自動計算した変動値の範囲で色分け'
      }
    ]);

    const router = useRouter();
    const { params } = useRoute();
    const {
      minDate,
      maxDate,
      calendarId,
      durationRef,
      durationError,
      areaSelectedValue,
      dataSection,
      targetAggregationUnit,
      isLoading,
      isTimeScreen,
      isView,
      basicTargetIds,
      currentTargetName,
      conditions,
      searchParams,
      buttonIsDisabled,
      selectOptions,
      isLoadingAreaOptions
    } = useHeatMapData();
    const {
      targetName,
      stationNames,
      seriesData,
      createDate,
      onChangeTarget,
      targetValid,
      fetchHeatMaps,
      getCustomTargets,
      onClickView,
      customTargets
    } = useHeatMap(dataSection);
    // カスタムターゲットの取得
    const currentCompanyId = Number(params.companyId?.toString());
    getCustomTargets(currentCompanyId);

    const customTargetList = computed(() => {
      return customTargets.value.map(({ id, name, isEnabled }) => ({
        id,
        label: name,
        isEnabled
      }));
    });

    // 検索条件変更の検知
    watch(
      [
        durationRef,
        areaSelectedValue,
        dataSection,
        targetAggregationUnit,
        basicTargetIds,
        currentTargetName
      ],
      () => {
        buttonIsDisabled.value = false;
      }
    );

    // カスタムターゲット購買ログ即時有効/無効判定用
    const isShowModal = ref<boolean>(false);
    const customTargetName = ref<string>('');
    const customTargetId = ref<number>(0);

    const showHeatMap = async () => {
      if (currentTargetName.value === TARGET_NAME.customTarget) {
        if (basicTargetIds.value?.length === 0) {
          toast({
            title: 'ターゲットを指定してください。',
            variant: 'error'
          });
          return;
        } else {
          // カスタムターゲット(購買ログ)の有効/無効をチェック
          const unwatch = watch([customTargets], () => {
            const _targetIds = basicTargetIds.value ?? [];
            const selectedCustomTarget = customTargets.value.find(
              target => target.id === _targetIds[0]
            );
            if (!selectedCustomTarget) {
              toast({
                title: 'ターゲットを指定してください。',
                variant: 'error'
              });
              return;
            }
            customTargetId.value = selectedCustomTarget.id;
            customTargetName.value = selectedCustomTarget.name;
            isShowModal.value = !selectedCustomTarget.isEnabled;
            unwatch();
          });
          getCustomTargets(currentCompanyId);
        }
      }
      let targetIds: Array<number> | undefined = [];
      let useCustomTarget = false;
      switch (currentTargetName.value) {
        case TARGET_NAME.individual:
          targetAggregationUnit.value = AGGREGATION_UNIT.personal;
          targetIds = undefined;
          break;
        case TARGET_NAME.household:
          targetAggregationUnit.value = AGGREGATION_UNIT.household;
          targetIds = undefined;
          break;
        case TARGET_NAME.customTarget:
          useCustomTarget = true;
          targetAggregationUnit.value = undefined;
          targetIds = basicTargetIds.value ?? [];
          break;
        default:
          targetAggregationUnit.value = undefined;
          targetIds = basicTargetIds.value ?? [];
          break;
      }

      searchParams.value.companyId = currentCompanyId;
      searchParams.value.startDate = durationRef.value?.start as Date;
      searchParams.value.endDate = durationRef.value?.end as Date;
      searchParams.value.areaCode = areaSelectedValue.value;
      searchParams.value.useCustomTarget = useCustomTarget;
      searchParams.value.dataDivision = dataSection.value;
      searchParams.value.targetIds = targetIds;
      searchParams.value.targetAggregationUnit = targetAggregationUnit.value;

      buttonIsDisabled.value = true;

      if (areaSelectedValue.value) {
        await onClickView(
          isLoading,
          isView,
          isTimeScreen,
          currentCompanyId,
          durationRef.value?.start as Date,
          durationRef.value?.end as Date,
          areaSelectedValue.value,
          useCustomTarget,
          dataSection.value,
          targetIds,
          targetAggregationUnit.value
        );
        nextTick(() => {
          scrollTo(`#heatmap`, 300, {
            offset: -160
          });
        });
      }
    };

    const goToCustomEdit = (customTargetId: number) => {
      router.push({
        name: CUSTOM_ROUTES.edit,
        params: { id: customTargetId, companyId: currentCompanyId }
      });
    };

    const viewingRate = TimeslotSearchConditionDataDivisionEnum.ViewingRate;
    const contentRate = TimeslotSearchConditionDataDivisionEnum.ContentRate;
    const contentRateHousehold =
      TimeslotSearchConditionDataDivisionEnum.ContentRateHousehold;

    return {
      minDate,
      maxDate,
      calendarId,
      durationRef,
      durationError,
      breadcrumbs,
      areaSelectedValue,
      dataSection,
      targetAggregationUnit,
      isLoading,
      isTimeScreen,
      isView,
      captions,
      targetName,
      stationNames,
      seriesData,
      createDate,
      onChangeTarget,
      targetValid,
      fetchHeatMaps,
      getCustomTargets,
      showHeatMap,
      customTargetList,
      conditions,
      searchParams,
      viewingRate,
      contentRate,
      contentRateHousehold,
      buttonIsDisabled,
      isShowModal,
      customTargetId,
      customTargetName,
      goToCustomEdit,
      selectOptions,
      isLoadingAreaOptions
    };
  }
});
