import { CustomTargetApi, TimeslotApi } from '@/api';
import {
  AreaInfoIdEnum,
  CustomTarget,
  TimeslotSearchConditionDataDivisionEnum,
  TimeslotTargetHeatMap
} from '@/api/openapi';
import { AGGREGATION_UNITS } from '@/common/constant';
import { AGGREGATION_UNIT } from '@/common/constant';
import { httpCode, TARGET_NAME } from '@/common/constant';
import { DATE_FORMAT } from '@/common/format';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { toast } from '@/components/ui/Toast';
import { useStationColor } from '@/composables/campaign/brand_lift/colors';
import { useDuration, DataType } from '@/composables/duration';
import router, { ROUTE_NAMES } from '@/router';
import { AreaOptions, useAreaOptions } from '@/store/areaOptions';
import Axios from 'axios';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

export type SeriesData = {
  x: number;
  y: number;
  value: number;
  font: string;
  className?: string;
};

export interface TimeSlotUseHeatMapDataIF {
  minDate: Ref<Date>;
  maxDate: Ref<Date>;
  calendarId: Ref<string>;
  durationRef: Ref<DateRange | undefined>;
  durationError: ComputedRef<string>;
  isLoadingDuration: Ref<boolean>;
  areaSelectedValue: Ref<AreaInfoIdEnum | undefined>;
  dataSection: Ref<TimeslotSearchConditionDataDivisionEnum>;
  targetAggregationUnit: Ref<AGGREGATION_UNITS | undefined>;
  isLoading: Ref<boolean>;
  isTimeScreen: Ref<boolean>;
  isView: Ref<boolean>;
  basicTargetIds: Ref<Array<number> | undefined>;
  currentTargetName: Ref<string>;
  conditions: Ref<ConditionInterface>;
  searchParams: Ref<SearchParams>;
  buttonIsDisabled: Ref<boolean>;
  isLoadingAreaOptions: Ref<boolean>;
  selectOptions: Ref<AreaOptions>;
}

interface ConditionInterface {
  duration: string;
  sampleSize: number;
  area: string;
  dataDivision: string;
  target: Array<string>;
  reportCreateDateTime: string;
}

const basicTargetIds = ref<Array<number> | undefined>([]);
const customTargetId = ref<number | undefined>();
const currentTargetName = ref<string>(TARGET_NAME.individual);
const conditions = ref<ConditionInterface>({
  duration: '',
  sampleSize: 0,
  area: '',
  dataDivision: '',
  target: [],
  reportCreateDateTime: ''
});

/**
 * データ系の関数
 */
export const useHeatMapData = (): TimeSlotUseHeatMapDataIF => {
  // 変数の初期化
  basicTargetIds.value = [];
  customTargetId.value = undefined;
  currentTargetName.value = TARGET_NAME.individual;
  conditions.value = {
    duration: '',
    sampleSize: 0,
    area: '',
    dataDivision: '',
    target: [],
    reportCreateDateTime: ''
  };

  const areaSelectedValue = ref<AreaInfoIdEnum>();
  const dataSection = ref(TimeslotSearchConditionDataDivisionEnum.ViewingRate);
  const targetAggregationUnit = ref<AGGREGATION_UNITS | undefined>(
    AGGREGATION_UNIT.personal
  );
  const isLoading = ref(false);
  const isTimeScreen = ref(false);
  const isView = ref(false);
  const searchParams = ref<SearchParams>({
    companyId: 0,
    startDate: new Date(),
    endDate: new Date(),
    areaCode: undefined,
    useCustomTarget: false,
    dataDivision: 'VIEWING_RATE',
    targetIds: [],
    targetAggregationUnit: AGGREGATION_UNIT.personal
  });
  const buttonIsDisabled = ref<boolean>(false);

  const selectOptionStore = useAreaOptions();
  const { isLoading: isLoadingAreaOptions, selectOptions } = storeToRefs(
    selectOptionStore
  );
  const route = useRoute();
  selectOptionStore.fetch(route).then(val => (areaSelectedValue.value = val));

  // 期間
  const {
    durationRef,
    minDate,
    maxDate,
    calendarId,
    isLoadingDuration,
    initDuration,
    calcDaysDifference
  } = useDuration(areaSelectedValue, DataType.TIME);
  initDuration();

  const durationError = computed(() => {
    const differenceDate = calcDaysDifference();
    if (differenceDate < 6) {
      return '7日間以上を選択してください';
    } else if (differenceDate >= 371) {
      return '期間は371日間以下で選択してください';
    }
    return '';
  });
  return {
    minDate,
    maxDate,
    calendarId,
    durationRef,
    durationError,
    isLoadingDuration,
    areaSelectedValue,
    dataSection,
    targetAggregationUnit,
    isLoading,
    isTimeScreen,
    isView,
    basicTargetIds,
    currentTargetName,
    conditions,
    searchParams,
    buttonIsDisabled,
    isLoadingAreaOptions,
    selectOptions
  };
};

/**
 * メソッド系の関数
 */
type ValueOf<T> = T[keyof T];

interface UseHeatMapIF {
  targetName: Ref<string>;
  stationNames: Ref<{ color: string; label: string }[] | undefined>;
  seriesData: Ref<SeriesData[]>;
  createDate: Ref<Date>;
  onChangeTarget: (targetValue: {
    name: ValueOf<{
      readonly individual: '個人全体';
      readonly genderAge12Type: '性・年齢12区分';
      readonly customTarget: 'カスタムターゲット';
      readonly gender10Type: '男女10歳区分';
    }>;
    value: number[] | { label: string; id: number };
  }) => void;
  targetValid: Ref<string>;
  fetchHeatMaps: (
    companyId: number,
    startDate: Date,
    endDate: Date,
    areaCode: AreaInfoIdEnum,
    useCustomTarget: boolean,
    dataDivision: TimeslotSearchConditionDataDivisionEnum,
    targetIds: number[],
    targetAggregationUnit: AGGREGATION_UNITS | undefined
  ) => Promise<void>;
  getCustomTargets: (companyId: number) => Promise<void>;
  onClickView: (
    isLoading: Ref<boolean>,
    isView: Ref<boolean>,
    isTimeScreen: Ref<boolean>,
    companyId: number,
    startDate: Date,
    endDate: Date,
    areaCode: AreaInfoIdEnum,
    useCustomTarget: boolean,
    dataDivision: TimeslotSearchConditionDataDivisionEnum,
    targetIds: Array<number> | undefined,
    targetAggregationUnit: AGGREGATION_UNITS | undefined
  ) => Promise<void>;
  customTargets: Ref<CustomTarget[]>;
}

export type TimeSlotStation = {
  label: string;
  color: string;
};

export type SearchParams = {
  companyId: number;
  startDate: Date;
  endDate: Date;
  areaCode: AreaInfoIdEnum | undefined;
  useCustomTarget: boolean;
  dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD';
  targetIds: Array<number> | undefined;
  targetAggregationUnit: AGGREGATION_UNITS | undefined;
};

export const useHeatMap = (
  dataSelection: Ref<TimeslotSearchConditionDataDivisionEnum>
): UseHeatMapIF => {
  const DIVIDE_NUM = 6;

  const seriesData = ref<SeriesData[]>([]);
  const stationNames = ref<{ color: string; label: string }[]>();
  const createDate = ref<Date>(new Date());
  const targetName = ref('個人全体');
  const customTargets = ref<CustomTarget[]>([]);

  const stationColor = useStationColor();

  const getCustomTargets = async (companyId: number) => {
    try {
      const {
        data: { list }
      } = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(companyId);
      if (list) {
        customTargets.value = list;
      }
    } catch {
      await router.push({
        name: ROUTE_NAMES.error
      });
      router.go(0);
    }
  };

  const setStationNames = (heatMaps: Array<TimeslotTargetHeatMap>) => {
    return heatMaps
      .map((heatmap: TimeslotTargetHeatMap) => ({
        label: heatmap.stationName ?? '',
        color: heatmap.stationCode
          ? stationColor.getStationColorByCode(heatmap.stationCode)
          : ''
      }))
      .filter((station: TimeSlotStation) => !!station.label);
  };

  const setHeatMapSeriesData = (heatmaps?: Array<TimeslotTargetHeatMap>) => {
    const values = heatmaps?.flatMap(heatmap =>
      heatmap.heatMapByDayOfWeek?.flatMap(week =>
        week.heatMapCells?.map(cell => cell.ratio)
      )
    ) as Array<number>;
    const max = Math.max(...values);
    const min = Math.min(...values);
    const level = (max - min) / DIVIDE_NUM;
    if (!heatmaps) return [];
    let seriesData: SeriesData[] = [];
    for (const heatmap of heatmaps) {
      if (heatmap.heatMapByDayOfWeek) {
        const stationIndex = heatmaps.findIndex(
          _heatmap => _heatmap.stationName === heatmap.stationName
        );
        for (const day of heatmap.heatMapByDayOfWeek) {
          if (day.heatMapCells) {
            const dayIndex =
              heatmap.heatMapByDayOfWeek?.findIndex(
                _day => _day.dayOfWeek === day.dayOfWeek
              ) ?? 0;
            const cells = day.heatMapCells.map(cell => ({
              x: dayIndex + stationIndex * 7,
              y: cell.broadcastTimezone ? cell.broadcastTimezone - 5 : 0,
              value: cell.ratio ?? 0, // ターゲット比率
              font:
                cell.ratio && cell.ratio > min + level * 2
                  ? 'var(--contrast-color)'
                  : 'var(--primary-color)'
            }));
            seriesData = [...seriesData, ...cells];
          }
        }
      }
    }
    return seriesData;
  };

  const onChangeTarget = (targetValue: {
    name: ValueOf<typeof TARGET_NAME>;
    value: Array<number> | { label: string; id: number };
  }) => {
    targetName.value = targetValue.name;
    currentTargetName.value = targetValue.name;
    if (Array.isArray(targetValue.value)) {
      switch (targetName.value) {
        // 基本属性
        case TARGET_NAME.genderAge12Type:
        case TARGET_NAME.gender10Type:
          basicTargetIds.value = targetValue.value;
          break;
      }
    } else if (targetName.value === TARGET_NAME.customTarget) {
      if (targetValue.value !== undefined) {
        basicTargetIds.value = [targetValue.value.id];
        customTargetId.value = targetValue.value.id;
      } else {
        basicTargetIds.value = [];
        customTargetId.value = undefined;
      }
    }
  };
  const targetValid = computed(() => {
    // 個人全体・世帯の場合
    if (
      (targetName.value === TARGET_NAME.individual ||
        targetName.value === TARGET_NAME.household) &&
      (dataSelection.value.includes('CONTENT_RATE') ||
        dataSelection.value.includes('CONTENT_RATE_HOUSEHOLD'))
    ) {
      return '含有率を指定する場合は、ターゲットを個人全体・世帯以外にしてください。';
    }

    // カスタムターゲットの場合
    if (
      targetName.value === TARGET_NAME.customTarget &&
      !customTargetId.value
    ) {
      return 'いずれか一つは選択してください。';
    }
    // 基本属性ターゲットの場合
    if (
      !(
        targetName.value === TARGET_NAME.customTarget ||
        targetName.value === TARGET_NAME.individual ||
        targetName.value === TARGET_NAME.household
      ) &&
      basicTargetIds.value?.length === 0
    ) {
      return 'いずれか一つは選択してください。';
    }
    return '';
  }); // valid
  const fetchHeatMaps = async (
    companyId: number,
    startDate: Date,
    endDate: Date,
    areaCode: AreaInfoIdEnum,
    useCustomTarget: boolean,
    dataDivision: TimeslotSearchConditionDataDivisionEnum,
    targetIds: Array<number> | undefined,
    targetAggregationUnit: AGGREGATION_UNITS | undefined
  ) => {
    const res = await TimeslotApi.getCompaniesCompanyIdTimeslotJson(
      companyId,
      format(startDate, DATE_FORMAT),
      format(endDate, DATE_FORMAT),
      areaCode,
      useCustomTarget,
      dataDivision,
      targetAggregationUnit,
      targetIds
    );
    stationNames.value = setStationNames(res.data.heatMaps);
    seriesData.value = setHeatMapSeriesData(res.data.heatMaps);

    const conditionResponse = await TimeslotApi.getTimeslotSearchCondition(
      format(startDate, DATE_FORMAT),
      format(endDate, DATE_FORMAT),
      areaCode,
      useCustomTarget,
      dataDivision,
      companyId,
      targetAggregationUnit,
      targetIds
    );

    conditions.value.sampleSize = conditionResponse.data.sampleSize;
    conditions.value.area = conditionResponse.data.area;
    conditions.value.dataDivision = conditionResponse.data.dataDivision;
    conditions.value.target = conditionResponse.data.target;
    conditions.value.reportCreateDateTime =
      conditionResponse.data.reportCreateDateTime;
  };

  const onClickView = async (
    isLoading: Ref<boolean>,
    isView: Ref<boolean>,
    isTimeScreen: Ref<boolean>,
    companyId: number,
    startDate: Date,
    endDate: Date,
    areaCode: AreaInfoIdEnum,
    useCustomTarget: boolean,
    dataDivision: TimeslotSearchConditionDataDivisionEnum,
    targetIds: Array<number> | undefined,
    targetAggregationUnit: AGGREGATION_UNITS | undefined
  ) => {
    try {
      isLoading.value = true;

      // ヒートマップデータ取得
      await fetchHeatMaps(
        companyId,
        startDate,
        endDate,
        areaCode,
        useCustomTarget,
        dataDivision,
        targetIds,
        targetAggregationUnit
      );

      isLoading.value = false;
      isView.value = true;
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
        isTimeScreen.value = true;
        toast({
          title: '失敗しました',
          message: 'ヒートマップの取得に失敗しました',
          variant: 'error'
        });
      } else {
        // 予期せぬエラーの場合
        router.push({
          name: ROUTE_NAMES.error
        });
      }
    } finally {
      isLoading.value = false;
    }
  };

  return {
    targetName,
    stationNames,
    seriesData,
    createDate,
    onChangeTarget,
    targetValid,
    fetchHeatMaps,
    getCustomTargets,
    onClickView,
    customTargets
  };
};
