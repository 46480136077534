import { ref, computed, Ref, ComputedRef } from 'vue';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { TimeslotApi } from '@/api';
import { DATE_FORMAT } from '@/common/format';
import saveAs from 'file-saver';
import Highcharts from 'highcharts';
import {
  AreaInfoIdEnum,
  TimeslotSearchConditionDataDivisionEnum
} from '@/api/openapi';
import { SeriesData } from '@/composables/datasearch/timeslot/heatmap';
import { AGGREGATION_UNITS } from '@/common/constant';

export interface UseChartOptionsIF {
  valueSection: Ref<string>;
  plotLeft: Ref<number>;
  getSelectDate: ComputedRef<{ start: string; end: string }>;
  chartCallback: (chart: Highcharts.Chart) => void;
  downloadHeatMap: (
    companyId: number,
    startDate: Date,
    endDate: Date,
    areaCode: AreaInfoIdEnum,
    useCustomTarget: boolean,
    dataDivision: TimeslotSearchConditionDataDivisionEnum,
    targetIds: number[],
    targetAggregationUnit: AGGREGATION_UNITS | undefined
  ) => Promise<void>;
  loading: Ref<boolean>;
  getAbsoluteRelativeClassNames: (value: SeriesData) => string;
  STATION_COUNT: number;
  week: Array<string>;
}

export const useChartOptions = (
  seriesData: Array<SeriesData>,
  selectedDate: { start: Date; end: Date }
): UseChartOptionsIF => {
  const valueSection = ref('変動値');
  const STATION_COUNT = 6;
  const week = ['月', '火', '水', '木', '金', '土', '日'];
  const values = seriesData.map((value: SeriesData) => value.value);
  const min = values.reduce((a: number, b: number) => {
    return Math.min(a, b);
  });
  const max = values.reduce((a: number, b: number) => {
    return Math.max(a, b);
  });
  const plotLeft = ref(0);
  const getAbsoluteRelativeClassNames = (value: SeriesData) => {
    // 視聴率:固定値クラス
    const absoluteLevel = Math.min(Math.ceil(value.value / 3), 7);
    const absoluteClassName = `absolute-level-${absoluteLevel}`;

    // 含有率(個人):固定値クラス
    const contentRatioAbsoluteLevel = Math.min(Math.ceil(value.value / 40), 7);
    const contentRatioAbsoluteClassName = `content-ratio-absolute-level-${contentRatioAbsoluteLevel}`;

    // 含有率(世帯):固定値クラス
    const contentRatioHouseholdAbsoluteLevel = Math.min(
      Math.ceil(value.value / 20),
      7
    );
    const contentRatioHouseholdAbsoluteClassName = `content-ratio-household-absolute-level-${contentRatioHouseholdAbsoluteLevel}`;

    // 変動値クラス
    const relativeLevel =
      max - min === 0
        ? 0
        : Math.min(Math.ceil((value.value - min) / ((max - min) / 7)), 7);
    const relativeClassName = `relative-level-${relativeLevel}`;
    return `${absoluteClassName} ${relativeClassName} ${contentRatioAbsoluteClassName} ${contentRatioHouseholdAbsoluteClassName}`;
  };

  const getSelectDate = computed(() => {
    const start = format(selectedDate.start, 'yyyy年MM月dd日(E)', {
      locale: ja
    });
    const end = format(selectedDate.end, 'yyyy年MM月dd日(E)', {
      locale: ja
    });
    return { start: start, end: end };
  });

  const chartCallback = (chart: Highcharts.Chart) => {
    plotLeft.value = chart.plotLeft;
  };

  const downloadHeatMap = async (
    companyId: number,
    startDate: Date,
    endDate: Date,
    areaCode: AreaInfoIdEnum,
    useCustomTarget: boolean,
    dataDivision: TimeslotSearchConditionDataDivisionEnum,
    targetIds: Array<number>,
    targetAggregationUnit: AGGREGATION_UNITS | undefined
  ) => {
    loading.value = true;
    const res = await TimeslotApi.getCompaniesCompanyIdTimeslotCsv(
      companyId,
      format(startDate, DATE_FORMAT),
      format(endDate, DATE_FORMAT),
      areaCode,
      useCustomTarget,
      dataDivision,
      targetAggregationUnit,
      targetIds
    );
    const mineType = res.headers['content-type'];
    const name = res.headers['content-disposition'];
    const reg = RegExp(/filename=(.*)/);
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data], {
      type: mineType
    });
    saveAs(blob, name.match(reg)[1]);
    loading.value = false;
  };

  const loading = ref<boolean>(false);

  return {
    valueSection,
    plotLeft,
    getSelectDate,
    chartCallback,
    downloadHeatMap,
    loading,
    getAbsoluteRelativeClassNames,
    STATION_COUNT,
    week
  };
};
